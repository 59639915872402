<template>
  <div>
    <div>
      <div class="bgimg">
        <img src="../assets/images/certificateBG.jpeg" alt="" />
      </div>
      <div class="box">
        <div>
          <span>公司证书</span>
          <div v-if="roleId == 0 || roleId == 2 || roleId == 5">
            <el-button type="primary" @click="$router.push('addcertificate')" v-if="CompanyID"
              >添加公司证书</el-button
            >
            <el-button
              v-if="CompanyID"
              type="primary"
              @click="delect = !delect"
              v-text="delect ? '取消删除' : '删除公司证书'"
            ></el-button>
            <el-button type="primary" @click="$router.push('usercompanyInfo')" 
              >我的公司信息</el-button
            >
            <el-popover
              v-if="Myaudit.type == 4"
              placement="bottom"
              width="400"
              trigger="click"
            >
              <el-form status-icon label-width="80px" class="demo-ruleForm">
                <el-form-item label="公司简介">
                  <el-input
                    v-model="Myaudit.about"
                    autocomplete="off"
                    type="textarea"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="insertAbout"
                    >提交</el-button
                  >
                </el-form-item>
              </el-form>
              <el-button
                :style="{ marginLeft: '10px' }"
                type="primary"
                slot="reference"
                
                >添加公司简介</el-button
              >
            </el-popover>
          </div>
        </div>
        <div>
          <div>
            <div @mouseenter="show1 = true" @mouseleave="show1 = false">
              <span @click="getCompanyCertify(1, true)">资格证书</span>
              <transition name="el-zoom-in-center">
                <div v-show="show1" class="transition-box"></div>
              </transition>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div @mouseenter="show2 = true" @mouseleave="show2 = false">
              <span @click="getCompanyCertify(2, true)">荣誉证书</span>
              <transition name="el-zoom-in-center">
                <div v-show="show2" class="transition-box"></div>
              </transition>
            </div>

            <el-divider direction="vertical"></el-divider>
            <div @mouseenter="show3 = true" @mouseleave="show3 = false">
              <span @click="getCompanyCertify(3, true)">公司业绩</span>
              <transition name="el-zoom-in-center">
                <div v-show="show3" class="transition-box"></div>
              </transition>
            </div>
            <el-divider direction="vertical"></el-divider>
          </div>
          <div>
            <i class="iconfont" :style="{ color: '#02449d' }">&#xe645;</i>
            <span class="toindex" @click="$router.push('/index')">首页</span>
            <span>/</span>
            <span>公司证书</span>
          </div>
        </div>
        <!-- 内容 -->
        <div v-if="CompanyCertifyList" class="CompanyCertifyList-box">
          <div v-for="(v, k) of CompanyCertifyList" :key="k">
            <div>
              <!-- <img :src="v.certifyImg" alt="" /> -->
              <el-image
                class="el-image"
                :src="v.certifyImg"
                :preview-src-list="srcList"
              >
              </el-image>
            </div>
            <div>
              <span>{{ v.title }}</span>
            </div>
            <div class="delete" v-if="delect">
              <el-popconfirm
                title="确定删除该证书吗？" @confirm="quedingdelect(v,k)">
                <el-button
                  slot="reference"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total"
            :hide-on-single-page="true"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <!-- z-index值的问题把my—header放到最后 -->
        <my-header></my-header>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      //商户判断
      CompanyID:false,
      // 公司信息
      Myaudit: "",
      srcList: [],
      //删除图标的显示隐藏
      delect: false,
      // 动画
      show1: false,
      show2: false,
      show3:false,
      // 证书列表
      CompanyCertifyList: [],
      type: 1,
      pageNum: 1,
      pageSize: 9,
      total: 9,
    };
  },
  // 权限信息
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
  },
  methods: {
    // handleImgClick(index) {
    //   // let tempImgList = [...this.imgList];
    //   // let temp = [];
    //   // for (let i = 0; i < index; i++) {
    //   // 	temp.push(tempImgList.shift());
    //   // }
    //   // this.viewerImgList = tempImgList.concat(temp);
    // },
    // 获取公司信息
    async getcompanyInfo() {
      const res = await this.$http({
        url: `front/company/findList?companyUserId=${sessionStorage.getItem(
          "userId"
        )}`,
      });
      this.Myaudit = res.data.data.list[0];
      // console.log(sessionStorage.getItem("companyId"),this.Myaudit.companyUserId)
      if(sessionStorage.getItem("userId")==this.Myaudit.companyUserId)
      {this.CompanyID=true
    }
      console.log(this.Myaudit);
    },
    // 获取证书
    async getCompanyCertify(type, click = false) {
      this.type = type;
      if (click) {
        this.pageNum = 1;
      }
      this.type = type;
      const res = await this.$http({
        method: "get",
        url: `front/CompanyCertify/findCompanyCertify?type=${
          this.type
        }&pageNum=${this.pageNum}&pageSize=${
          this.pageSize
        }&companyId=${sessionStorage.getItem("userId")}`,
      });
      console.log(res.data.data);
      this.total = res.data.data.total;
      this.CompanyCertifyList = res.data.data.list;
      res.data.data.list.map((v, k) => {
        this.srcList.push(v.certifyImg);
      });
      console.log(this.srcList);
    },
    // 分页
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getCompanyCertify(this.type);
    },
    
    // 确定删除公司证书
    async quedingdelect(v, k) {
      console.log(v);
      console.log(k);
      const res = await this.$http({
        method: "post",
        url: "front/CompanyCertify/delete",
        data: {
          id: v.id,
        },
      });
      console.log(res.data);
      if (res.data.status == 200) {
        this.CompanyCertifyList.splice(k, 1);
      }
      location.reload()
    },
    // 修改餐饮公司简介
    async insertAbout() {
      if (this.Myaudit.type == 4) {
        console.log(2);
        const res = await this.$http({
          method: "post",
          url: "front/company/insertAbout",
          data: {
            id: this.Myaudit.id,
            about: this.Myaudit.about,
          },
        });
        if (res.status == 200) {
          this.reload();
        }
      }
    },
  },
  created() {
    this.getCompanyCertify(this.type);
    this.getcompanyInfo();
    
  },
};
</script>

<style lang="scss" scoped>
.bgimg {
  width: 100%;
  margin-top: 130px;
  img {
    width: 100%;
  }
}
.box {
  & ::v-deep .el-image-viewer__close {
    // color: #000;
    // z-index: 10000;
    // opacity: 0.8;
    & ::v-deep .el-icon-circle-close {
      // color: #000;
      // position: relative;
    }
  }
  width: 90%;
  background: #fff;
  position: relative;
  top: -160px;
  z-index: 15;
  margin: 0 auto;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  & > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    font-size: 40px;
    color: #004098;
    padding: 40px;
    padding-left: 70px;
    user-select: none;
    & > div:nth-child(2) {
      margin-right: 150px;
    }
  }
  & > div:nth-child(2) {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    div:nth-child(1) {
      display: flex;
      margin-left: 20px;
      .el-divider--vertical {
        margin-top: 4px;
      }
      div:hover span {
        color: #009fc9;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .transition-box {
          background-color: #009fc9;
          width: 90px;
          height: 3px;
          margin-top: 30px;
        }
        span {
          padding: 0 25px;
          color: #555;
        }
      }
    }
    & > div:nth-child(2) {
      margin-right: 180px;
      i {
        vertical-align: sub;
      }
      span {
        margin: 0 3px;
        color: #888;
        user-select: none;
      }
      .toindex {
        color: #666;
        cursor: pointer;
        outline: #004098;
      }
      .toindex:hover {
        text-decoration: underline;
      }
    }
  }
}
.CompanyCertifyList-box {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  margin-top: 40px;
  width: 100%;
  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 31%;
    padding: 13px;
    margin-bottom: 40px;
    .delete {
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 50%;
    }
    div:nth-child(1) {
      overflow: hidden;
      width: 100%;
      height: 320px;
      //   border: 1px solid #ddd;
      box-shadow: 0 0.2px 1px 0 rgba(0, 0, 0, 0.5);
      & ::v-deep .el-image {
        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: 320px;
          transition: 0.5s linear 0s;
        }
      }
    }
    div:nth-child(2) {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      border-top: 0;
      padding: 12px;
      text-align: center;
      transition: 0.5s ease 0s;
      z-index: 1000;
      span {
        font-size: 16px;
        font-weight: bold;
        color: #666;
        transition: 0.3s ease 0s;
      }
    }
  }
  & > div:hover {
    div:nth-child(2) {
      background: #009fc9;
      span {
        color: #fff;
      }
    }
    div:nth-child(1) {
      & ::v-deep .el-image {
        img {
          transform: scale(1.15);
          transition: 0.5s linear 0s;
        }
      }
    }
  }
}
.pages {
  margin: 20px;
  display: flex;
  justify-content: flex-end;
  .el-pagination {
    margin: 0px 50px 30px 0px;
  }
}
.el-image {
  & ::v-deep .el-image-viewer__wrapper {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 2000 !important;
  }
  & ::v-deep .el-image-viewer__img {
    max-width: 50% !important;
    height: 550px !important;
    margin: auto !important;
  }
}
</style>